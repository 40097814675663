.modals-wrapper {
    display: none;
}
.modal-container{
    width: 460px;
    background: #CECFD1 !important;
    display: flex;
    flex-direction: column;
    padding: 48px 60px;
    @media screen and (max-width: 991px) {
        width:90%
    }
    .form-title{
        color: #171D25;
        text-align: center;
        font-family: MuseoSansCyrl-500;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 100% */
        text-transform: uppercase;
        margin-bottom: 27px;
    }
    label{
        position: relative;
        display: flex;
        input{
            border-radius: 4px;
            border: 1px solid #F8F8F8;
            background: #D9D9D9;
            width: 100%;
            height: 55px;
            font-family: MuseoSansCyrl-500;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px; 
            color: #000;
            margin-bottom: 22px;
            padding-left: 20px;
            &::placeholder{
                color: #7C7C7C;
               
            }
            &.error{
                border: 1px solid #FF0000;
            }
        }
        textarea{
            border-radius: 4px;
            border: 1px solid #F8F8F8;
            background: #D9D9D9;
            width: 100%;
            height: 100px;
            font-family: MuseoSansCyrl-500;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px; 
            color: #000;
            margin-bottom: 22px;
            padding-left: 20px;
            &::placeholder{
                color: #7C7C7C;
               
            }
            &.error{
                border: 1px solid #FF0000;
            }
        }
        span{
            &.error{
                bottom: 4px;
                color: red;
                font-size: 12px;
                left: 0;
                position: absolute;
                white-space: nowrap;
            }
        }
        button{
            border-radius: 4px;
            border: 1px solid #F8F8F8;
            background: #232930;
            width: 100%;
            height: 55px;
            font-family: MuseoSansCyrl-500;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; 
            color: #FFFFFF;
            margin-bottom: 22px;
            transition: .3s linear;
            &:hover{
                background: #FF0000;
            }
        }
    }
    .spacer{
        background: #14181D;
        width: 100%;
        height: 1px;
        display: flex;
        margin-bottom: 20px;
    }
    .passwordRecovery-link{
        color: #171D25;
        text-align: center;
        font-family: MuseoSansCyrl-500;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 38px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .soc-links{
        width: 100%;
        margin-bottom: 50px;
        display: flex;
        justify-content: start;
        flex-direction: column;
        p{
            color: #171D25;
            text-align: start;
            font-family: MuseoSansCyrl-500;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;  
            margin-top: 22px;
        }
        .soc-link{
            display: flex;
            justify-content: space-around;
            margin-top: 42px;
        }
        a{
            display: flex;
            color: #171D25;
            text-align: center;
            font-family: MuseoSansCyrl-500;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; 
            svg{
                margin-right: 10px;
            }
        }
    }
    .registerBtn_link{
        display: flex;
        width: 100%;
        height: 55px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border-radius: 4px;
        border: 1px solid #F8F8F8;
        background: #232930;
        transition: .3s linear;
        font-family: MuseoSansCyrl-500;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; 
        color: #FFFFFF;
        &:hover{
            background: #FF0000;
        }
    }
}