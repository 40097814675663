// resetCss
@import "css-reset";

@import "global";
// mixins
@import "mixin";

// Fonts
@import "../fonts/Museo/stylesheet";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css";

@import "modal";

body{
    .preloder_wrapper{
        background: rgb(0 0 0 / 50%);
        display: none;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }
    &.preloder{
        .preloder_wrapper{
            display: flex;
        }
    }
}

header {
    height: 100px;
    width: 100%;
    background: rgba(35,41,48,0.7);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    z-index: 5;
    box-shadow: 0px 4px 4px rgba(35, 41, 48, 0.25);

    &.header_mobile{
        background: #232930;
        height: 130px;
        .header_mobile_top{
            height: 60px;
            align-items: center;
            border-bottom: 1px solid #656565;
            .burger {
                width: 28px;
                height: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                position: relative;
                span {
                    display: flex;
                    height: 2px;
                    width: 28px;
                    background: #fff;
                    transform-origin: center;
                    position: absolute;
                    &:nth-child(1) {
                        top: 0;
                        transition: transform 0.3s, 0.5s top 0.3s;
                    }
                    &:nth-child(2) {
                        width: 24px;
                        @include bgRed;
                        top: 6px;
                        transition: 0.5s opacity 0.3s;
                    }
                    &:nth-child(3) {
                        top: 12px;
                        transition: transform 0.3s, 0.5s top 0.3s;
                    }
                }
                &.cross {
                    span {
                        &:nth-child(1) {
                            top: 5px;
                            transform: rotate(45deg);
                            transition: top 0.3s, 0.5s transform 0.3s;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                            transition: 0s opacity 0.1s;
                        }
                        &:nth-child(3) {
                            top: 5px;
                            transform: rotate(-45deg);
                            transition: top 0.3s, 0.5s transform 0.3s;
                        }
                    }
                }
            }
        }
        .right{
            .lang{
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;
                a{
                    font-family: 'MuseoSansCyrl-300';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.01em;
                    color: #FFFFFF;
                    transition: .5s;
                    margin: 0 4px;
                    &.active, &:hover{
                        color: #FF0000;
                        transition: .5s;
                    }
                }
            }
        }
        .header_mobile_bottom{
            height: 70px;
        }
    }
    
    .container {
        box-sizing: border-box;
        padding: 0 60px;
        width: 100%;
        max-width: 100%;
        height: 100px;
        margin: auto;        
        display: grid;
        grid-template-columns: 12% 12% 48% 28%;
        align-items: center;
        position: relative;
        @media screen and (max-width: 1400px) {
            grid-template-columns: 13% 10.54% 48% 28%;
            padding: 0 30px;
        }
        @media screen and (max-width: 1300px) {
            grid-template-columns: 14% 10.54% 52% 24%;
            padding: 0 30px;
        }
        @media screen and (max-width: 1200px) {
            grid-template-columns: 14% 4% auto 24%;
            padding: 0 10px;
        }
        @media screen and (max-width: 991px) {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
        }
    }
    .header_contact{
        display: flex;
        flex-direction: column;
        a{
           @include textLight16;
           color: #F8F8F8;
           text-decoration: none;
           &:first-child{
            margin-bottom: 3px;
           }
           @media screen and (max-width: 1200px) {
            font-size: 14px;
           }
           @media screen and (max-width: 991px) {
            font-size: 15px;
           }
        }
    }
    .header_mess{
        display: flex;
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
        @media screen and (max-width: 991px) {
            flex-direction: row;
        }
        a{
            margin-right: 8px;
            @media screen and (max-width: 1200px) {
                margin-right: 0;
                margin-bottom: 4px;
            }
            @media screen and (max-width: 991px) {
                margin-right: 10px;
            }
            svg{
                display: flex;
                width: 25px;
                height: 26px;
                &path{
                    transition: .5s;
                }
            }
            &:hover svg path{
                fill:red;
                transition: .5s;
            }
        }
    }
    .header_center{
        display: flex;
        flex:0 0 865px;
        flex-direction: column;
        .logo{
            display: flex;
            justify-content: center;
            margin-left: 10%;
            margin-bottom: 6px;
        }
        .menu{
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;
                li{
                    &:hover .menu-child{
                        display: flex;
                    }
                    .menu-child{
                        position: absolute;
                        left: 0;
                        padding: 15px 0 0 0;
                        width: 100%;
                        display: none;
                        ul{
                            background: rgba(217, 217, 217, 0.6);
                            height: 60px;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            box-shadow: 0 4px 4px rgba(217, 217, 217,.25);
                            a{
                                font-family: 'MuseoSansCyrl-300';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 140%;
                                display: flex;
                                align-items: center;
                                text-transform: uppercase;
                                color: #000000;
                                margin: 0 20px;

                            }
                        }
                    }
                }
                a{
                    @include textLight18;
                    color: #ffffff;
                    text-decoration: none;
                    transition: .5s;
                    position: relative;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

                    @media screen and (max-width: 1450px) {
                        font-size: 15px;
                    }
                    &::after{
                        content: '';
                        display: block;
                        transform: scaleX(0);
                        transition: .5s;
                    }
                    &:hover, &.action{
                        &::after{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background: #ffffff;
                            position: absolute;
                            bottom: 0;
                            transform: scaleX(1);
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
    .header_right{
        display: flex;
        flex:0 0 248px;
        flex-direction: column;
        
        .header_right_bottom{
            display: flex;
            justify-content: flex-end;
            height: 25px;
            margin-top: 8px;
            
            .header_right_bottom_land{
                display: flex;
                a{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.01em;
                    color: #FFFFFF;
                    transition: .5s;
                    text-transform: uppercase;
                    margin-left: 8px;
                    &.active, &:hover{
                        color: #FF0000;
                        transition: .5s;
                    }
                }
            }
        }
    }
    .header_search{
        display: flex;
        flex:0 0 142px;
        margin-left: 58px;
        @media screen and (max-width: 1300px) {
            margin-left: 10%;
        }   
        @media screen and (max-width: 1200px) {
            margin-left: 4%;
        }              
        @media screen and (max-width: 992px) {
            background: #D9D9D9;
            border-radius: 5px;
            height: 24px;
        }              
        button{
            background: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
            margin-left: 5px;
            @media screen and (max-width: 992px) {
                margin-left: 0;
                display: flex;
                align-items: center;
            }            
            svg{
                path{
                    @media screen and (max-width: 992px) {
                        fill: #000;
                    }
                }
            }
        }
        input{
            background: transparent;
            border: none;
            border-bottom: 1px solid #fff;
            color: #fff;
            padding: 7px 8px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            flex: 0 0 121px;
            width: 121px;
            justify-content: center;
            align-items: center;
            &:focus, &:focus-visible{
                outline:none;
            }
            &::placeholder{
                color: #ffffff;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                @media screen and (max-width: 992px) {
                    color: #000;
                }
            }
            @media screen and (max-width: 992px) {
                border-bottom: none;
                color: #000;
            }   
        }
    }
    .cart_lk{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 6px;
        a{
            width: 25px;
            height: 25px;
            margin-left: 36px;
            position: relative;
            @media screen and (max-width: 991px) {
                margin-left: 18px;
                &:first-child{
                    margin-left: 0;
                }
            }
            &.basketLink::after, 
            &.favoritesLink::after{
                content: attr(data-num);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                align-items: center;
                letter-spacing: -0.01em;
                width: 14px;
                height: 14px;
                color: #fff;
                position: absolute;
                top: 0;
                right: 0;
                background: #FF0000;
                transition: .5s;
                opacity: 1
            }
            &.basketLink[data-num='0']::after,
            &.favoritesLink[data-num='0']::after{
                opacity: 0;
                transition: .5s;
            }
        }
    }
    .header-anchors{
        background: #232930;
        display: flex;
        height: calc(100vh - 60px);
        max-width: 304px;
        width: 100%;
        position: fixed;
        top: 60px;
        transition: .5s;
        transform: translateX(-100%);
        z-index: 10;
        padding: 50px 25px 0;
        &.show{
            transform: translateX(0);
        }
        ul{
            list-style: none;
            padding-left: 0;
            li{
                a{
                    @include textLight18;
                    color: #FFFFFF;
                    line-height: 160%;
                }
                .menu-child{
                    padding-left: 20px;
                }
            }
        }
    }
}
.feedback-block {
    width: 100%;
    @include bgDark_light;
    padding: 40px 0;
    form {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        @media screen and (max-width: 1140px) {
            flex-direction: column;
        }
        .form-title {
            @include textBold18;
            margin-right: 80px;
            color: #f8f8f8;
            @media screen and (max-width: 1140px) {
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
        .input-wrapper {
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            label {
                display: flex;
                margin-right: 35px;
                position: relative;
                @media screen and (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 35px;
                }
            }
            .inpItem {
                width: 192px;
                height: 25px;
                border: none;
                border-bottom: 1px solid #656565;
                background: none;
                @include textLight18;
                color: #f8f8f8;
                @media screen and (max-width: 768px) {
                    width: 310px;
                }
            }
            .sendBtn {
                width: 200px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                border: none;
                @include bgRed;
                @include textBold19;
                color: #fff;
                @media screen and (max-width: 768px) {
                    width: 310px;
                }
            }
        }
    }
}
footer {
    margin-top: auto;
    display: flex;
    padding: 75px 0 !important;
    background: #101418;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .footer-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        img {
            max-width: 200px;
            width: 160px;
        }
    }
    .social-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .messengers-link {
            display: flex;
            &:first-child {
                margin-right: 15px;
            }
            svg {
                width: 43px;
                height: 43px;
            }
        }
    }
}

.svg-container{
    display: none;
}
::-webkit-scrollbar-track {
    background-color: #e1e1e1;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 20px;
    border-radius: 0;
    background-color: red;
}

::-webkit-scrollbar {
    width: 5px;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  